
export function getDevice (param, cb, errorCb) {
    window.axios({
      method: 'get',
      url: `devices?deviceType=${param.deviceType}&pageSize=&mac=&type=&continuationToken&tenantId=${param.tenantId}`
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function getTelemetryLastest(data, cb, errorCb) {
    window.axios({
        method: 'post',
        url: `telemetry-lastest`,
        data: data
      }).then(response => {
        cb(response)
      }).catch(error => {
        errorCb(error)
      })
}



export function getTelemetryPromise (param) {
    return window.axios({
      method: 'get',
      url: `telemetry?pageSize=&continuationToken=${param.continuationToken}&trackingId=${param.trackingId}&startTime=${param.startTime}&endTime=${param.endTime}`
    })
  }

  export function getDeviceSummaryPromise (param) {
    return window.axios({
      method: 'get',
      url: `device-summary?pageSize=&continuationToken=${param.continuationToken}&trackingId=${param.trackingId}&startTime=${param.startTime}&endTime=${param.endTime}`
    })
  }



export function getAlertRule(param, cb, errorCb) {
  window.axios({
      method: 'get',
      url: `/alert-rule?&tenantId=${param.tenantId}`
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function postUploadFile(data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `/files`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getBuilding(cb, errorCb) {
   window.axios({
    method: 'get',
    url: `/building?`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getAsset(param, cb, errorCb) {
  window.axios({
   method: 'get',
   url: `/assets?buildingId=${param.buildingId}`
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function postAsset(data, cb, errorCb) {
  window.axios({
   method: 'post',
   url: `/assets`,
   data: data
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function getAssetType(cb, errorCb) {
  window.axios({
   method: 'get',
   url: `/asset-type`
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function getServices(cb, errorCb) {
  window.axios({
   method: 'get',
   url: `/services`
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function getEnergy(param, cb, errorCb) {
  window.axios({
   method: 'get',
   url: `/energy?buildingId=${param.buildingId}&type=${param.type}&assetId=${param.assetId}${(param.start) ? '&start=' + param.start : ''}${(param.end) ? '&end=' + param.end : ''}${(param.group) ? '&group=' + param.group : ''}`
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function getEnergyPredicted(param, cb, errorCb) {
  window.axios({
   method: 'get',
   url: `/energy-predict?buildingId=${param.buildingId}&assetId=${param.assetId}${(param.start) ? '&start=' + param.start : ''}${(param.end) ? '&end=' + param.end : ''}${(param.group) ? '&group=' + param.group : ''}`
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function getEnergyReprocess(param, cb, errorCb) {
  // console.log(`/energy?buildingId=${param.buildingId}&type=${param.type}&deviceId=${param.deviceId}${(param.start) ? '&start=' + param.start : ''}${(param.end) ? '&end=' + param.end : ''}${(param.group) ? '&group=' + param.group : ''}`)
  window.axios({
   method: 'get',
   url: `/energy-re-process?buildingId=${param.buildingId}&assetId=${param.assetId}&period=${param.period}&maximum=${param.maximum}`
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function getWater(param, cb, errorCb) {
  window.axios({
   method: 'get',
   url: `/water?buildingId=${param.buildingId}&type=${param.type}&deviceId=${param.deviceId}${(param.start) ? '&start=' + param.start : ''}${(param.end) ? '&end=' + param.end : ''}${(param.group) ? '&group=' + param.group : ''}`
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function getLevel(param, cb, errorCb) {
  window.axios({
   method: 'get',
   url: `/level?buildingId=${param.buildingId}&type=${param.type}&assetId=${param.assetId}${(param.start) ? '&start=' + param.start : ''}${(param.end) ? '&end=' + param.end : ''}${(param.group) ? '&group=' + param.group : ''}`
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function getAir(param, cb, errorCb) {
  window.axios({
   method: 'get',
   url: `/air?buildingId=${param.buildingId}&type=${param.type}&deviceId=${param.deviceId}${(param.start) ? '&start=' + param.start : ''}${(param.end) ? '&end=' + param.end : ''}`
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function getNetwork(cb, errorCb) {
  window.axios({
   method: 'get',
   url: `/network?`
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function getElectrical(param, cb, errorCb) {
  window.axios({
   method: 'get',
   url: `/electrical?buildingId=${param.buildingId}${(param.assetId) ? '&assetId=' + param.assetId : ''}${(param.start) ? '&start=' + param.start : ''}${(param.end) ? '&end=' + param.end : ''}`
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function getDistBoard(param, cb, errorCb) {
  window.axios({
   method: 'get',
   url: `/dist-board?buildingId=${param.buildingId}`
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function getWTP(param, cb, errorCb) {
  window.axios({
   method: 'get',
   url: `/wtp?buildingId=${param.buildingId}`
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function getHVACPlant(param, cb, errorCb) {
  window.axios({
   method: 'get',
   url: `/hvac-plant?buildingId=${param.buildingId}`
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function getChiller(param, cb, errorCb) {
  window.axios({
   method: 'get',
   url: `/chiller?buildingId=${param.buildingId}`
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function updateNetwork(data, cb, errorCb) {
  window.axios({
   method: 'patch',
   url: `/network?`,
   data: data
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function anomalyLearning(data, cb, errorCb) {
  window.axios({
   method: 'post',
   url: `/anomaly?`,
   data: data
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function getConnector(param, cb, errorCb) {
  window.axios({
   method: 'get',
   url: `/connector?buildingId=${param.buildingId}`
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function getMember(param, cb, errorCb) {
  window.axios({
   method: 'get',
   url: `/member?buildingId=${param.buildingId}`
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function getTelemetry(param, cb, errorCb) {
  window.axios({
      method: 'get',
      url: `telemetry?&buildingId=${param.buildingId}&assetId=${param.assetId}&start=${param.start}&end=${param.end}`
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function getScore(param, cb, errorCb) {
  window.axios({
      method: 'get',
      url: `score?&buildingId=${param.buildingId}`
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function getLine(param, cb, errorCb) {
  window.axios({
      method: 'get',
      url: `line?&buildingId=${param.buildingId}&assetId=${param.assetId}`
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function postLine(data, cb, errorCb) {
  window.axios({
      method: 'post',
      url: `line`,
      data: data
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function sendLine(data, cb, errorCb) {
  window.axios({
      method: 'patch',
      url: `line`,
      data: data
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function deleteLine(data, cb, errorCb) {
  window.axios({
      method: 'delete',
      url: `line`,
      data: data
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function getEmail(param, cb, errorCb) {
  window.axios({
      method: 'get',
      url: `email?&buildingId=${param.buildingId}&assetId=${param.assetId}`
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function postEmail(data, cb, errorCb) {
  window.axios({
      method: 'post',
      url: `email`,
      data: data
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function deleteEmail(data, cb, errorCb) {
  window.axios({
      method: 'delete',
      url: `email`,
      data: data
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function getAlert(param, cb, errorCb) {
  window.axios({
      method: 'get',
      url: `alert?&buildingId=${param.buildingId}${(param.start) ? '&start=' + param.start : ''}${(param.end) ? '&end=' + param.end : ''}`
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function getAlertByAsset(param, cb, errorCb) {
  window.axios({
      method: 'get',
      url: `alert?&buildingId=${param.buildingId}&assetId=${param.assetId}`
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function getAlertLatest(param, cb, errorCb) {
window.axios({
    method: 'get',
    url: `alert/latest?&buildingId=${param.buildingId}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getUser(cb, errorCb) {
  window.axios({
    method: 'get',
    url: `user`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getUnit(cb, errorCb) {
  window.axios({
    method: 'get',
    url: `unit`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getADUser(cb, errorCb) {
  window.axios({
    method: 'get',
    url: `ad-users`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postMember(data, cb, errorCb) {
  window.axios({
   method: 'post',
   url: `/member`,
   data: data
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function deleteMember(data, cb, errorCb) {
  window.axios({
   method: 'delete',
   url: `/member`,
   data: data
 }).then(response => {
   cb(response)
 }).catch(error => {
   errorCb(error)
 })
}

export function getRules(param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `/rules?&buildingId=${param.buildingId}&assetId=${param.assetId}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function createRules(data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `/rules`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteRules(data, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: `/rules`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function updateRules(data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: `/rules`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getNotifyToken(param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `/line?&buildingId=${param.buildingId}&assetId=${param.assetId}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function createNotifyToken(data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `/line`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteNotifyToken(data, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: `/line`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getReportList(param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `/report?&buildingId=${param.buildingId}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getReport(data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `/report`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postAcknowledge(data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `/acknowledge`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postSignalR(data, cb, errorCb) {
  window.axios({
      method: 'post',
      url: `signalr`,
      data: data
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}